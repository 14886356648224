/* Modal Backdrop */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Dim background */
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* New Query Modal */
  .new-query-modal {
    position: relative;
    width: 429px;
    background: #f4f6f9;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 40px 32px 40px; /* Increased top and bottom padding */
    text-align: center;
  }
  
  /* Modal Title */
  .new-query-modal h2 {
    font-family: 'DM Sans', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    color: #2e2b2b;
    margin-bottom: 16px; /* Adjust spacing below title */
  }
  
  /* Modal Text */
  .new-query-modal p {
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #2e2b2b;
    margin-bottom: 32px; /* Reduce spacing below text */
  }
  
  /* Modal Actions */
  .modal-actions {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  /* Primary Button */
  .primary-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 120px;
    height: 44px;
    background: #641770;
    border-radius: 28px;
    color: #f2f1f1;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px; /* Matches icon size */
    font-weight: 600;
    line-height: 24px;
    cursor: pointer;
    border: none;
  }
  
  /* Secondary Button */
  .secondary-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 112px;
    height: 44px;
    border: 2px solid #862f94;
    border-radius: 28px;
    color: #862f94;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px; /* Matches icon size */
    font-weight: 600;
    line-height: 24px;
    cursor: pointer;
    background: none;
  }