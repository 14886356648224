.inputWrapper {
    display: flex;
    align-items: flex-start; /* Align items to the top */
    width: 100%;
    padding: 10px; /* Optional: Adjust padding for spacing */
}

.questionInputTextArea {
    flex-grow: 1;
    background-color: white;
    border: 3px solid #d3d3d3; /* Thin grey border */
    border-radius: 40px; /* Inner border radius */
    padding: 12px;
    box-shadow: 0 0 0 5px white; /* Outer thicker white border */
}

.buttonsContainer {
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Center the buttons horizontally */
    margin-left: 20px; /* Space between text area and buttons */
    gap: 10px; /* Space between stacked buttons */
}

.primaryButtons {
    display: flex;
    gap: 10px; /* Space between primary buttons */
}

.questionInputSendButton {
    cursor: pointer;
}

.questionInputSendButtonDisabled {
    opacity: 0.4;
    pointer-events: none;
}

.additionalButtons {
    display: flex;
    flex-direction: column; /* Stack additional buttons vertically */
    gap: 10px; /* Space between additional buttons */
    padding: 10px; /* Padding for additional buttons */
    align-items: center; /* Center additional buttons horizontally */
}
