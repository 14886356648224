/* Navbar container */
.custom-navbar {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    width: 100%; /* Full width */
    height: 80px;
    background: #FFFFFF4D;
    border-bottom: 1px solid var(--Colour-Neutrals-Steel-steel-400, #A7B6D1); 
    border-radius: 5px;
}

/* Nav Logo Section */
.nav-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    cursor: pointer;  /* Show pointer when hovering */
}

/* Logo Image */
.logo-img {
    width: 24px;
    height: 24px;
    object-fit: contain;
}

/* Logo Title */
.logo-title {
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #322F2E;
}

/* Circle Button */
.circle-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: #641770;
    border-radius: 50%;
}

/* Icon inside the button */
.icon {
    font-size: 20px;   /* Adjust for bold appearance */
    font-weight: 700;  /* Bold dots */
    color: white;
    line-height: 20px;  /* Align vertically */
    text-align: center;
}

/* Ensure button is visible on smaller screens */
@media (max-width: 768px) {
    .custom-navbar {
        flex-direction: row;
        padding: 0 10px;
        height: 60px;
    }

    .circle-button {
        width: 36px;
        height: 36px;
    }

    .logo-title {
        font-size: 14px;
    }
}

/* Hide button on very small screens if necessary */
@media (max-width: 480px) {
    .circle-button {
        display: none;
    }
}
