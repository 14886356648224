/* ReferencePDFModal.css - simplified for PDF viewing only */
.reference-pdf-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999; /* Ensure it's on top */
  display: flex;
  align-items: center;
  justify-content: center;
}

.reference-pdf-modal-content {
  background: #fff;
  width: auto;
  max-width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;
}

.close-button {
  position: absolute;
  right: -50px;
  background: white !important;
  border: 2px solid grey !important;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Target the Fluent UI button inside the close button */
.close-button .ms-Button {
  background: white !important;
  color: grey !important;
  border: none !important;
}

/* Change color on hover */
.close-button:hover {
  border-color: purple !important;
}

.close-button:hover .ms-Button {
  color: purple !important;
}

.pdf-viewer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-canvas {
  border: none;
}

.loadingSpinner {
  width: 100px;
  height: 100px;
  border: 10px solid #f3f3f3; /* Light gray border */
  border-top: 10px solid purple; /* Purple accent */
  border-radius: 50%;
  animation: spinner 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spinner {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

