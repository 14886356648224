/* IframePage.css */

.iframe-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.iframe-full {
  width: 100%;
  height: 100%;
  border: none;
}

.box {
  padding: 20px;
  text-align: center;
  border-radius: 10px;
}

.has-text-white {
  color: #fff;
}
