.chat-input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: auto;
    padding-bottom: 20px;
}

.chat-input-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 6px 12px 6px;
    gap: 8px;

    width: 95%;
    height: 48px;

    background: #FFFFFF;
    border-radius: 28px;
    border: 1px solid #BDC8DC;
    position: relative;
}

.input-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.audio-canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 28px;
    background: transparent;
    z-index: 1;
}

.chat-input-container input {
    width: 100%;
    height: 24px;
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    line-height: 24px;
    border: none;
    outline: none;
    color: #818181;
    background: transparent;
    position: relative;
    z-index: 2;
}

.chat-input-container input.active-input {
    font-weight: 700;
    color: #000000;
}

.chat-input-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: #641770;
    border-radius: 50%;
    cursor: pointer;
    color: white;
}

.mic-icon {
    color: white;
    font-size: 18px;
}

.mic-icon.recording {
    color: #28a745;
}

/* Clear Button Styled as Chat Button */
.clear-input-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: #641770;
    border-radius: 50%;
    color: white;
    cursor: pointer;
}