.aviation-home-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: calc(100vh - 80px);  /* Subtract navbar height */
    position: relative;
  
    /* Fallback background color */
    background-color: #F7F2F7; 
  
    /* Use the image once, scaled up slightly */
    background-image: url("../../images/home-page-background.png");
    background-repeat: no-repeat;
    background-size: 60% auto;  /* Scale width to 120% of container width */
    background-position: 110% bottom; /* Shift right so the left side gets cropped */
  }
  


/* Logo Styling */
.aviation-home-logo {
    width: 200px;
    margin: 0 auto;
    margin-top: 100px;
}

/* Main Text */
.aviation-home-text {
    font-family: 'DM Sans', sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    color: #171B23;
    margin-top: 40px;
}

/* Description */
.aviation-home-description {
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #7C7673;
    margin-top: 20px;
    margin-bottom: 40px;
}

/* Purple Highlight */
.highlight-purple {
    color: #641770;
}

/* Button Default */
.aviation-home-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 24px 10px 16px;
    gap: 10px;
    min-width: 254px;  /* Minimum width to avoid shrinking too much */
    height: 56px;
    background: #641770;
    color: white;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 28px;
    cursor: pointer;
    transition: all 0.3s ease;
}

/* Button Icon (Left of Text) */
.button-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;  /* Space between icon and text */
}

/* Button Hover */
.aviation-home-button:hover {
    transform: scale(1.05);  /* Grows by 5% */
    background: #4B0D54;
}
