/* Apply the background image with a gradient fade effect */
.background-container {
  background-color: #FFFFFF !important; /* Fallback color */
  background-image: 
    linear-gradient(to bottom, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 200%),
    url('/public/business-network-background-connecting-dots-technology-design.jpg'); /* Adjusted path */
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevents repeating of the background image */
  min-height: 100vh; /* Ensure the container takes full height */
}

.hero.is-fullheight {
  min-height: 100vh; /* Ensure the hero section takes full height */
}

/* Make the footer share the same background */
.footer {
  background: transparent; /* Set to transparent to show parent background */
  padding: 20px; /* Add some padding */
  color: #000000; /* Adjust text color for visibility */
}

/* Ensure content is positioned above the overlay */
.hero-body {
  position: relative;
  z-index: 1; /* Places content above the gradient */
}

.gradient-text {
  background: linear-gradient(90deg, #EE75FF, #00E5FF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

/* Box container */
.box-container {
  position: relative;
  overflow: hidden;
  padding: 15px; /* Reduced padding for smaller screens */
  border: 2px solid #EAE8E7;
  border-radius: 15px; /* Reduced border radius for better fit */
  background: #FFFFFF;
  transition: border-color 0.3s, transform 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box; /* Ensure padding and border are included in the element's width and height */
}

.box-container:hover,
.box-container.selected {
  border-color: #EE75FF;
  transform: scale(1.05);
}

.columns {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  flex: 1 1 50%;
  box-sizing: border-box;
  padding: 0 15px;
  margin-bottom: 20px;
}

.button {
  background-color: #D564E0;
  color: white;
  border: none;
  outline: none;
  margin-top: 20px;
  padding: 15px 50px;
  transition: background-color 0.3s, color 0.3s;
}

.button:hover {
  background-color: #EE75FF;
}

.tags-wrapper {
  background-color: #EAE8E7;
  padding: 5px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: background-color 0.3s, color 0.3s;
  color: #000000;
}

.tag-box {
  text-overflow: ellipsis; /* Add ellipsis for overflowed text */
  margin-right: 5px; /* Space between tags */
}

.tag-box::before {
  content: '○';
  margin-right: 5px;
}

.tags-wrapper:hover {
  background: linear-gradient(90deg, #EE75FF, #00E5FF);
  color: #ffffff;
}

.text-nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .title.is-6 {
  font-size: 0.875rem;
} */

/* Title: Ensure the text wraps if necessary */
.title.is-5 {
  font-size: 1.25rem; /* Default size */
  white-space: normal; /* Allow text to wrap */
  overflow: visible; /* Ensure the overflow is visible */
  text-overflow: clip; /* Disable ellipsis */
  width: 100%; /* Ensure the text fits the container width */
  word-wrap: break-word; /* Break words if necessary */
}

/* Dynamically reduce font size for smaller screens */
@media (max-width: 1000px) {
  .title.is-5 {
    font-size: 1rem; /* Reduce font size for smaller screens */
  }
}

@media (max-width: 768px) {
  .title.is-5 {
    font-size: 0.875rem; /* Further reduce the font size */
  }
}


.tags-wrapper {
  display: flex;
  flex-wrap: wrap;
}