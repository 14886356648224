/* Message Container */
.message-container {
    display: flex;
    align-items: flex-start; /* Align logo and bubble at the top */
    gap: 12px; /* Space between the logo and the bubble */
    margin-bottom: 20px;
    width: 100%;
}

/* Logo Styling */
.message-logo {
    width: 20px;
    height: 20px;
    flex-shrink: 0; /* Prevent the logo from resizing */
}

/* Message Bubble */
.message-bubble {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    padding: 24px;

    width: 100%;
    max-width: 90%; /* Prevents overflow by using percentage */
    background: #FFFFFF;
    border-radius: 2px 16px 16px 16px;
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    line-height: 22px;
    color: #2E2B2B;
    border: 1px solid #BDC8DC;
    flex-shrink: 1; /* Allow shrinking on small screens */
}

/* System Message Styling */
.system-message {
    align-self: flex-start;
    background: #FFFFFF;
    border: 1px solid #BDC8DC;
    border-radius: 2px 16px 16px 16px;
    font-weight: 400;
    color: #2E2B2B;
}

/* User Message Styling */
.user-message {
  display: flex;
  justify-content: flex-end;     /* Ensures content inside .user-message is right-aligned */
  align-items: center;           /* Vertically center content if needed */
  margin-left: auto;             /* Pushes the entire .user-message bubble to the right */
  padding: 10px 16px;
  gap: 10px;
  width: auto;
  max-width: 80%;                /* Keeps the bubble from being too wide */
  height: auto;
  background: #7686A1;
  border-radius: 16px 2px 16px 16px;
  font-weight: 600;
  text-align: right;
  color: #FFFFFF;
}


/* Message Text Sizing */
.message-bubble > p {
    width: auto;
    height: auto;
    margin: 0;
}

.loading-container {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .loading-gif {
    width: 24px;
    height: 24px;
  }
  
  .loading-text {
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    color: #2E2B2B;
  }
  
/* Tasks Grid */
.tasks-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(241.5px, 1fr)); /* Ensure minimum width */
    gap: 16px; /* Spacing between cards */
    margin: 16px 0;
  }
  
  /* Task Card */
  .task-card {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 12px;
    background: #e4eaf3;
    border: 1px solid #bdc8dc;
    border-radius: 8px;
    min-width: 241.5px; /* Minimum width for the card */
    max-width: 300px; /* Maximum width for the card */
  }

  /* Hover Effect: Shadow, Gradient Border, Darkened Background */
  .task-card:hover {
    background: #d6dce7; /* Slightly darkened background */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Slightly larger shadow */
    border-image-source: linear-gradient(143.38deg, #CB5EDB 1.25%, #33EAFF 96.67%);
    border-image-slice: 1; /* Enable gradient border */
  }
  
  /* Task Number */
  .task-card strong {
    font-size: 1.2em;
    color: black;
  }
  
  /* Task Pages */
  .task-card .task-pages {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 0.9em;
    color: #91A4C5;
  }
  

  .message-text strong {
    color: #000000; /* Set bold text color to black */
    font-weight: bold;
  }