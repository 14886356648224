/* Main Chat Layout */
.chat-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);  /* 12-column layout */
  gap: 20px;
  margin: 0;
  height: calc(100vh - 80px);  /* Subtract navbar height */
  overflow: hidden;  /* Prevent scrolling */
  /* Radial Gradient Background */
  background: radial-gradient(76.2% 82.55% at 50% 11.63%, #F7F2F7 13%, #D6DEED 100%);
}

/* Sidebar (3 columns each) */
.sidebar-left, .sidebar-right {
  grid-column: span 3;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

/* Chat Main Section (6 columns) */
.chat-main {
  grid-column: span 6;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
}

/* Scrollable Chat Window */
.chat-window {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

/* Input Container */
.chat-input-container {
  display: flex;
  /* padding: 16px; */
  border-top: 1px solid #ddd;
  background: #fff;
}

/* Sidebar Buttons */
/* Sidebar Buttons Group */
/* Sidebar Buttons Group */
.button-group {
  display: flex;
  flex-direction: column; /* Ensure buttons are listed top-to-bottom */
  gap: 10px; /* Space between buttons */
  margin: 20px auto; /* Center the button group horizontally */
  width: calc(100% - 40px); /* Padding around buttons */
  max-width: 300px; /* Set a max width for buttons on larger screens */
  box-sizing: border-box; /* Include padding in width calculation */
  align-items: stretch; /* Stretch buttons to fill the container width */
}

/* Chat Button */
.chat-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 16px; /* Larger padding for bigger screens */
  gap: 8px;
  width: 100%; /* Make button take full width of the container */
  height: 48px; /* Set consistent button height */
  max-width: 300px; /* Set a max width for buttons */
  background: #f4f6f9;
  border: 1px solid #bdc8dc;
  border-radius: 6px; /* Slightly more rounded corners */
  font-family: 'DM Sans', sans-serif;
  font-size: 16px; /* Larger font for better readability */
  font-weight: 600;
  text-transform: uppercase;
  color: #000000;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  overflow: hidden; /* Ensure content doesn't overflow */
  text-overflow: ellipsis; /* Show ellipsis when text overflows */
  white-space: nowrap; /* Prevent text from wrapping */
}

/* Chat Button on Small Screens */
@media (max-width: 820px) {
  .chat-button {
    justify-content: center; /* Center the icon */
    padding: 12px; /* Reduce padding for smaller screens */
  }

  .chat-button span {
    display: none; /* Hide the text */
  }
}

/* Chat Button Icon */
.chat-button .icon {
  width: 20px; /* Larger icon size for better visibility */
  height: 20px;
  color: #7686a1; /* Default icon color */
  flex-shrink: 0; /* Prevent icon from shrinking */
}

/* Chat Button Hover */
.chat-button:hover {
  background: #d3dbe8;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15); /* Slightly larger shadow on hover */
  border-image-source: linear-gradient(143.38deg, #CB5EDB 1.25%, #33EAFF 96.67%);
  border-image-slice: 1;

}

/* Active Chat Button */
.chat-button.active {
  background: linear-gradient(90deg, #7686a1 12.43%, #5b677d 71.89%);
  color: #ffffff;
  border-left: 5px solid #ed75ff;
  box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.3);
}

/* Active Chat Button Icon */
.chat-button.active .icon {
  color: #ffffff; /* Make the icon white when the button is active */
}

/* Disabled Chat Button */
.chat-button:disabled {
  background: #bdc8dc;
  color: #7a7a7a; /* Greyed out text for disabled buttons */
  cursor: not-allowed;
  box-shadow: none; /* Remove shadow for disabled buttons */
}

.references-header {
  display: flex;
  align-items: center;
  gap: 8px; /* Adjust spacing between icon and text */
  color: #323A47; /* Ensures both icon and text are black */
}

.references-header .icon {
  font-size: 20px; /* Adjust icon size if needed */
  color: #323A47;
}