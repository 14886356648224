.pdf-thumbnail {
  position: relative;
  width: 169px;
  height: 219px;
  background: #e4eaf3;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: box-shadow 100ms ease-out, border 100ms ease-out;
  border: 1.5px solid transparent;
}

.pdf-thumbnail:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-image-source: linear-gradient(143.38deg, #CB5EDB 1.25%, #33EAFF 96.67%);
  border-image-slice: 1;
}

.pdf-thumbnail:hover .page-info {
  background: #641770;
}

.pdf-multi-container {
  position: relative;
  padding: 20px;
  overflow-y: auto;
}

.pdf-thumbnails {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(169px, 1fr));
  gap: 16px;
}

.thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
}

.page-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;
  gap: 4px;
  position: absolute;
  top: 8px;
  left: 8px;
  background: #862f94;
  border-radius: 4px;
  color: #f4f6f9;
  font-size: 12px;
  font-weight: 600;
  z-index: 1;
  white-space: nowrap;
  transition: background-color 100ms ease-out;
  font-family: "DM Sans";
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.link-icon {
  transform: rotate(-45deg);
  color: #f4f6f9;
  font-size: 14px;
}

/* Full PDF Viewer */
.pdf-full-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pdf-navigation {
  /* Container for all PDF navigation elements */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  margin-top: 10px;

  /* Optional translucent background styling */
  background: rgba(118, 134, 161, 0.4);
  backdrop-filter: blur(5px);
  border-radius: 4px;

  /* A small gap to separate any "extra" button from the group of Prev/Page/Next */
  gap: 12px;
}

/* Group for the Prev button, Page info, and Next button (no gap among them) */
.pdf-navigation-group {
  display: flex;
  align-items: center;
  gap: 0; /* ensure buttons & page info are flush */
}

/* PAGE INFO in the middle */
.pdf-page-info {
  font-size: 14px;
  font-weight: bold;
  color: #000;

  /* Match the "button" look so it merges nicely */
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  background: #F4F6F9;
  border: 1px solid #A7B6D1;
  height: 44px;

  /* Remove left & right borders if you want truly seamless edges */
  /* border-left: none;   */
  /* border-right: none; */
}

/* BASE BUTTON STYLES for Prev/Next (and optionally others) */
.pdf-button-prev,
.pdf-button-next,
.pdf-button-extra {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;      /* internal icon/text gap */
  width: 44px;
  height: 44px;

  background: #F4F6F9;
  border: 1px solid #A7B6D1;
  color: grey;   /* default icon/text color */
  cursor: pointer;
}

/* PREV: rounded left corners, no right border if merging with page info */
.pdf-button-prev {
  border-radius: 4px 0 0 4px;
  border-right: none; /* so it merges seamlessly with .pdf-page-info */
}

/* PAGE INFO is in the middle, can remove left/right border to fuse with prev/next if you like */

/* NEXT: rounded right corners, no left border if merging with page info */
.pdf-button-next {
  border-radius: 0 4px 4px 0;
  border-left: none;
}

/* .pdf-button-extra can be fully rounded or the same style—your choice: */
.pdf-button-extra {
  border-radius: 4px; /* fully rounded corners for a standalone button */
}

/* HOVER STATES: Purple border & purple icon color */
.pdf-button-prev:hover,
.pdf-button-next:hover,
.pdf-button-extra:hover {
  border: 1px solid transparent;
  border-image-source: linear-gradient(143.38deg, #CB5EDB 1.25%, #33EAFF 96.67%);
  border-image-slice: 1; /* Enable gradient border */
}

/* For Fluent UI IconButtons, target the inner icon's class: */
.pdf-button-prev:hover .ms-Button-icon,
.pdf-button-next:hover .ms-Button-icon,
.pdf-button-extra:hover .ms-Button-icon {
  color: purple !important;
}

/* When disabled, remove the hover effect and prevent clicking. */
.pdf-button-prev:disabled:hover,
.pdf-button-next:disabled:hover,
.pdf-button-extra:disabled:hover {
  border: 1px solid #A7B6D1; /* or whatever your default non-hover border is */
  border-image-source: none; /* remove gradient */
  cursor: not-allowed;
  pointer-events: none;      /* ensures no click or hover events */
}

/* Optionally style the disabled state so it looks “greyed out” or distinct. */
.pdf-button-prev:disabled,
.pdf-button-next:disabled,
.pdf-button-extra:disabled {
  opacity: 0.5; /* or some style indicating disabled */
  pointer-events: none; /* if you want to fully block any events */
}

.pdf-viewer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #000; */
  overflow: auto;
  height: auto;
}

.pdf-canvas {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 80vh;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; /* Ensures the spinner is above other content */
}

.loadingSpinner {
  width: 100px;
  height: 100px;
  border: 10px solid #f3f3f3; /* Light gray border */
  border-top: 10px solid purple; /* Purple accent */
  border-radius: 50%;
  animation: spinner 1s linear infinite;
}