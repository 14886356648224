/* AviationMaintenance.module.css */

.chatContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px; /* Increased width for the chat box */
    height: 600px; /* Set a height for the chat box */
    margin: 0 auto;
    background-color: white; /* White background for the chat box */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.chatBox {
    flex-grow: 1;
    overflow-y: auto; /* Enable scrolling */
    max-height: 100%; /* Use full height */
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc; /* Optional border */
    border-radius: 5px; /* Optional rounded corners */
}

/* Adjusting message bubble styling */
.messageBubble {
    padding: 10px;
    border-radius: 15px;
    margin: 5px 0;
    max-width: 75%; /* Limit width for better appearance */
    word-wrap: break-word; /* Break long words */
}

/* Align user messages to the right and system messages to the left */
.userMessage {
    background-color: #e0e0e0; /* Light grey background for user messages */
    align-self: flex-end; /* Align to the right */
}

.systemMessage {
    background-color: #d3f8d3; /* Light green background for system messages */
    align-self: flex-start; /* Align to the left */
}

/* Ensure the parent container of messages allows flex alignment */
.chatBox {
    display: flex; /* Make chatBox a flex container */
    flex-direction: column; /* Align items in a column */
    justify-content: flex-start; /* Align items at the start */
    gap: 10px; /* Space between messages */
}

.numberedText {
    cursor: pointer; /* Indicate clickable text */
    margin: 5px 0;  /* Spacing between lines */
    color: white;    /* Change text color if desired */
}

